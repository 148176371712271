<template>
  <v-container fluid class="pa-8">
    <v-row class="pb-4">
      <GsNavButtons />
    </v-row>
    <v-form
      class="mb-6"
      style="width: 100%"
      v-model="valid"
      ref="form"
      v-if="dataProvider"
    >
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('name')"
            v-model="dataProvider.name"
            :rules="formRules.name"
            :autofocus="true"
          />
          <v-select
            :label="$t('type')"
            v-model="dataProvider.type"
            :rules="formRules.type"
            :items="availableTypes"
            :menu-props="{ maxHeight: '400' }"
          />
          <v-text-field
            :label="$t('url')"
            v-model="dataProvider.url"
            :rules="formRules.url"
          />
          <v-text-field
            :label="$t('timeout')"
            v-model="dataProvider.timeout"
            :rules="formRules.timeout"
          />
          <v-text-field
            :label="$t('checkPointPattern')"
            v-model="dataProvider.checkPointPattern"
            :rules="formRules.checkPointPattern"
          />
          <v-select
            :label="$t('parser')"
            v-model="dataProvider.parser"
            :rules="formRules.parser"
            :items="availableParsers"
            :menu-props="{ maxHeight: '400' }"
          />
          <v-select
            :label="$t('mapper')"
            v-model="dataProvider.mapper"
            :rules="formRules.mapper"
            :items="availableMapper"
            :menu-props="{ maxHeight: '400' }"
          />
        </v-col>
      </v-row>

      <!-- errors -->
      <div class="d-flex flex-row" v-if="error">
        <span class="subtitle-1 mb-4 red--text"
          >{{ $t("form_invalid_text") }}: {{ error }}</span
        >
      </div>

      <!-- <actions> -->
      <div class="d-flex flex-row">
        <v-spacer />
        <!-- <v-btn class="ma-2" color="default" @click="cancel_click">
          {{ $t("cancel") }}
        </v-btn> -->
        <v-btn
          class="ma-2"
          color="primary"
          @click="save_click"
          :loading="loading"
        >
          {{ $t("save") }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
const fieldValidators = require("@/managers/fieldValidators");
import GsNavButtons from "@/components/GsNavButtons";

export default {
  name: "DataProviderEditor",
  components: {
    GsNavButtons
  },
  props: {
    cancel: {
      type: Function,
      default: () => {}
    },
    save: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      mode: null,
      dataProvider: null,
      formRules: {
        name: [fieldValidators.requiredString],
        type: [fieldValidators.requiredString],
        url: [fieldValidators.requiredString],
        timeout: [fieldValidators.requiredNumber],
        checkPointPattern: [fieldValidators.requiredString],
        parser: [fieldValidators.requiredString],
        mapper: [fieldValidators.requiredString]
      },
      valid: true,
      error: null,
      loading: false,
      scopes: []
    };
  },
  watch: {
    "$route.params.dataProviderId": {
      immediate: true,
      handler(v) {
        if (v === "new") {
          this.mode = "create";
          this.createDataProvider();
          return;
        }
        this.mode = "edit";
        this.init();
        this.loadDataProvider(v);
      }
    }
  },
  computed: {
    availableTypes() {
      return ["stream", "poll", "push"];
    },
    availableParsers() {
      return ["json"];
    },
    availableMapper() {
      return ["idf", "cello", "teltonika"];
    }
  },
  methods: {
    async init() {
      await this.setBreadCrumbs();
    },
    createDataProvider() {
      this.dataProvider = {
        dataProviderId: null,
        name: null,
        type: null,
        url: null,
        timeout: null,
        checkPointPattern: null,
        parser: "json",
        mapper: null
      };
    },
    async loadDataProvider(dataProviderId) {
      const url = `${this.$store.state.idp_api_url}/data-providers/${dataProviderId}`;
      const method = "GET";
      try {
        const response = await callAPI({ url, method });
        this.dataProvider = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    async cancel_click() {
      this.$router.go(-1);
    },
    async save_click() {
      this.error = null;

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close")
          }
        });
        return;
      }

      let url = `${this.$store.state.idp_api_url}/data-providers`;
      if (this.mode == "edit") url += `/${this.dataProvider.dataProviderId}`;

      const method = this.mode == "create" ? "POST" : "PATCH";
      let data = {
        name: this.dataProvider.name || undefined,
        type: this.dataProvider.type || undefined,
        url: this.dataProvider.url || undefined,
        timeout: this.dataProvider.timeout || undefined,
        checkPointPattern: this.dataProvider.checkPointPattern || undefined,
        parser: this.dataProvider.parser || undefined,
        mapper: this.dataProvider.mapper || undefined
      };

      try {
        this.loading = true;
        const response = await callAPI({ url, method, data });
        if (response.status == 204 || response.status == 200) {
          this.$router.go(-1);
        }
      } catch (err) {
        const data = err?.response?.data;

        if (data.statusCode == 400) {
          this.error = this.$t(data.validation.keys.join(", "));
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
