<template>
  <v-toolbar dense background-color>
    <!-- <v-btn-toggle dense>
      <v-btn
        v-for="(item, index) in fixedButtons"
        :key="index"
        @click="onItemClick(item)"
        :disabled="isActive(item.href)"
        :class="{
          'mr-2': index < fixedButtons.length - 1
        }"
        color="primary"
      >
        <v-icon left :color="item.color">{{ item.icon }}</v-icon>
        {{ $t(item.title) }}
      </v-btn>
    </v-btn-toggle> -->

    <v-tabs :value="activeFixedButtonIndex" fixed-tabs>
      <v-tab
        v-for="(item, index) in fixedButtons"
        :key="index"
        @click="onItemClick(item)"
      >
        <v-icon left :color="item.color" dense>{{ item.icon }}</v-icon>
        {{ $t(item.title) }}
      </v-tab>
    </v-tabs>

    <v-spacer></v-spacer>

    <!-- Menu with items -->
    <v-menu bottom left v-if="remainingMenuItems.length > 0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in remainingMenuItems"
          :key="index"
          @click="onItemClick(item)"
        >
          <v-list-item-icon class="mr-4">
            <v-icon v-text="item.icon" :color="item.color"></v-icon>
          </v-list-item-icon>
          <v-list-item-content class="mr-4">
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
export default {
  name: "NGTRespNavButtons",
  props: {
    numOfFixButtonsOnDesktop: {
      type: Number,
      default: 5
    },
    numOfFixButtonsOnMobile: {
      type: Number,
      default: 2
    },
    navItems: {
      type: Array,
      default: () => [
        {
          title: "Button 1",
          href: "/button1",
          icon: "mdi-account",
          color: "primary"
        },
        {
          title: "Button 2",
          href: "/button2",
          icon: "mdi-account",
          color: "primary"
        },
        {
          title: "Button 3",
          href: "/button3",
          icon: "mdi-account",
          color: "primary"
        },
        {
          title: "Button 4",
          href: "/button4",
          icon: "mdi-account",
          color: "primary"
        },
        {
          title: "Button 5",
          href: "/button5",
          icon: "mdi-account",
          color: "primary"
        },
        {
          title: "Button 7",
          href: "/button7",
          icon: "mdi-account",
          color: "primary"
        },
        {
          title: "Button 8",
          href: "/button8",
          icon: "mdi-account",
          color: "primary"
        },
        {
          title: "Button 9",
          href: "/button9",
          icon: "mdi-account",
          color: "primary"
        },
        {
          title: "Button 10",
          href: "/button10",
          icon: "mdi-account",
          color: "primary"
        }
      ]
    }
  },
  data() {
    return {
      tab: 0
    };
  },
  computed: {
    fixedButtons() {
      const totalButtons = this.$vuetify.breakpoint.smAndDown
        ? this.numOfFixButtonsOnMobile
        : this.numOfFixButtonsOnDesktop;
      return this.navItems.slice(0, totalButtons);
    },
    remainingMenuItems() {
      return this.navItems.filter(item => !this.fixedButtons.includes(item));
    },
    activeFixedButtonIndex() {
      return this.fixedButtons.findIndex(p => this.$route.path === p.href);
    }
  },
  methods: {
    onItemClick(item) {
      // console.log(item.href);
      this.$router.push(item.href);
    },
    isActive(route) {
      return this.$route.path === route;
    }
  }
};
</script>
