const Joi = require("@hapi/joi");
import { i18n } from "@/locales/i18n";

export function optionalUri(v) {
  const schema = Joi.string()
    .uri()
    .allow(null, "");
  const result = schema.validate(v);
  return result.error ? i18n.t("it_must_be_an_uri") : true;
}

export function requiredUri(v) {
  const schema = Joi.string()
    .required()
    .uri();
  const result = schema.validate(v);
  return result.error ? i18n.t("it_must_be_an_uri") : true;
}

export function optionalString(v) {
  return true;
}

export function requiredString(v) {
  const schema = Joi.string().required();
  const result = schema.validate(v);
  return result.error ? i18n.t("required") : true;
}

export function optionalNumber(v) {
  return true;
}

export function requiredNumber(v) {
  const schema = Joi.number().required();
  const result = schema.validate(v);
  return result.error ? i18n.t("required") : true;
}

export function optionalArray(v) {
  return true;
}

export function requiredArray(v) {
  const schema = Joi.array().required();
  const result = schema.validate(v);
  return result.error ? i18n.t("required") : true;
}
