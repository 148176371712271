<template>
  <NGTRespNavButtons :navItems="navItems" />
</template>

<script>
import NGTRespNavButtons from "@/components/NGT/NGTRespNavButtons";

export default {
  name: "GsNavButtons",
  components: {
    NGTRespNavButtons
  },
  props: {},
  data() {
    return {
      entityName: null,
      entityId: null
    };
  },
  computed: {
    navItems() {
      if (!this.entityId) return [];
      let items = [
        {
          title: "attrs",
          href: `/${this.entityName}/${this.entityId}/attrs`,
          icon: "mdi-clipboard-text",
          color: "secondary",
          enabled: true
        },
        {
          title: "tags",
          href: `/${this.entityName}/${this.entityId}/tags`,
          icon: "mdi-tag",
          color: "warning",
          enabled: true
        },
        {
          title: "profiles",
          href: `/${this.entityName}/${this.entityId}/profiles`,
          icon: "mdi-account",
          color: "primary",
          enabled: this.entityName === "identities"
        },
        {
          title: "tokens",
          href: `/${this.entityName}/${this.entityId}/tokens`,
          icon: "mdi-key-variant",
          color: "default",
          enabled: this.entityName === "identities"
        },
        {
          title: "edit",
          href: `/${this.entityName}/${this.entityId}`,
          icon: "mdi-pencil",
          color: "accent",
          enabled: true
        }
      ];
      return items.filter(p => p.enabled);
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(v) {
        const p = this.$route.path.split("/");
        this.entityName = p[1];
        this.entityId = p[2];
        // console.log(`${this.entityName} ${this.entityId}`);
      }
    }
  }
};
</script>
